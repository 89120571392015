import styles from "./SaveChanges.module.css";

export default function SaveChanges(){
    return(
        <div className={styles.save_main}>
            <button>
                Save Changes
            </button>
        </div>
    )
}