import React from 'react'
import styles from './filter.module.css'

export default function Filter() {


   return (
      <div className={styles['container']}>
         <h2>
            This is filter modal
         </h2>
      </div>
   )
}
