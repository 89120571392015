import React from 'react'

export default function Blogs(props) {
   

   return (
      <>
         Blogs
      </>
   )
}
