import React, { useState } from "react";
import styles from "./Contactus.module.css";
import PrimaryButton from "../../Buttons/PrimaryButton/PrimaryButton.js";

import contactUsBanner from "../../../assets/images/ContactusImages/contactusBanner.png";

const Contactus = () => {
 
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    agreeToPrivacyPolicy: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // submission logic here
    console.log(formData);
  };
  return (
    <section className={styles["container"]} id="contactUs">
      <h1 className={styles["heading"]}>Contact us</h1>
      <div className={styles["contactWrapper"]}>
        <div className={styles["Image"]}>
          <img src={contactUsBanner} />
        </div>
        <div className={styles["formContainer"]}>
          <div className={styles["formHeader"]}>
            <h1>We’d love to help!</h1>
            <p>Reach out and we’ll get in touch within 24 hours</p>
          </div>

          <form onSubmit={handleSubmit} className={styles["formFields"]}>
            <div className={styles["nameFields"]}>
              <label className={styles["firstname"]}>
                First Name:
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="First name"
                />
              </label>
              <label className={styles["lastname"]}>
                Last Name:
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Last name"

                />
              </label>
            </div>
            <div className={styles["emailField"]}>
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="e.g. person@gmail.com"

                />
              </label>
            </div>
            <div className={styles["messageField"]}>
              <label>
                Message:
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Leave us a message..."
                />
              </label>
              <div className={styles["checkboxField"]}>
                <label>
                  <input
                    type="checkbox"
                    name="agreeToPrivacyPolicy"
                    checked={formData.agreeToPrivacyPolicy}
                    onChange={handleChange}
                  />
                  <div className={styles["checkboxText"]}>                  
                  You agree to our friendly &nbsp;
                  <a className={styles["privacyPolicy"]}>privacy policy</a>
                  </div>
                </label>
              </div>
            </div>
            <PrimaryButton type="submitButton" children={"Get in touch"} className={styles.apply} />
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contactus;
