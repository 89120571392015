export const salonContent = [
    {
      cardImage: require("../../assets/images/SalonsPageImages/cardImage.png"),
      star: require("../../assets/images/SalonsPageImages/star.png"),
      ellipse: require("../../assets/images/SalonsPageImages/Ellipse.png"),
      mapPin: require("../../assets/images/SalonsPageImages/map-pin.png"),
      chevronRight: require("../../assets/images/SalonsPageImages/chevron-right.png"),
      name: "Athena Hair Salon & Unisex Spa",
      rating: 3.8,
      ratingsCount: 1361,
      location: "Windmills Road, Bengaluru",
      distance: 570,
      unit:"m",
      services: [
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "599" },
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "499" },
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "399" },
      ],
      recommended:true,
      niche:"Facial and Makeup",
      venueType:"everyone",
    },
    {
      cardImage: require("../../assets/images/SalonsPageImages/cardImage.png"),
      star: require("../../assets/images/SalonsPageImages/star.png"),
      ellipse: require("../../assets/images/SalonsPageImages/Ellipse.png"),
      mapPin: require("../../assets/images/SalonsPageImages/map-pin.png"),
      chevronRight: require("../../assets/images/SalonsPageImages/chevron-right.png"),
      name: "She Hair & Beauty",
      rating: 4.4,
      ratingsCount: 122,
      location: "Ejipura, Bengaluru",
      distance: 2.4,
      unit:"km",
      services: [
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "599" },
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "399" },
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "199" },
      ],
      recommended:true,
      niche:"Massage parlour",
      venueType:"female",
    },
    {
      cardImage: require("../../assets/images/SalonsPageImages/cardImage.png"),
      star: require("../../assets/images/SalonsPageImages/star.png"),
      ellipse: require("../../assets/images/SalonsPageImages/Ellipse.png"),
      mapPin: require("../../assets/images/SalonsPageImages/map-pin.png"),
      chevronRight: require("../../assets/images/SalonsPageImages/chevron-right.png"),
      name: "Geetanjali Beauty Salon",
      rating: 4.9,
      ratingsCount: 1361,
      location: "Koramangala, Bengaluru",
      distance: 170,
      unit:"m",
      services: [
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "1599" },
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "1299" },
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "1199" },
      ],
      recommended:true,
      niche:"Facial and Makeup",
      venueType:"male",
    },
    {
      cardImage: require("../../assets/images/SalonsPageImages/cardImage.png"),
      star: require("../../assets/images/SalonsPageImages/star.png"),
      ellipse: require("../../assets/images/SalonsPageImages/Ellipse.png"),
      mapPin: require("../../assets/images/SalonsPageImages/map-pin.png"),
      chevronRight: require("../../assets/images/SalonsPageImages/chevron-right.png"),
      name: "Geetanjali Salon",
      rating: 3,
      ratingsCount: 161,
      location: "Koramangala, Bengaluru",
      distance: 70,
      unit:"m",
      services: [
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "299" },
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "199" },
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "99" },
      ],
      recommended:false,
      niche:"Unisex spa",
      venueType:"everyone",
    },
    {
      cardImage: require("../../assets/images/SalonsPageImages/cardImage.png"),
      star: require("../../assets/images/SalonsPageImages/star.png"),
      ellipse: require("../../assets/images/SalonsPageImages/Ellipse.png"),
      mapPin: require("../../assets/images/SalonsPageImages/map-pin.png"),
      chevronRight: require("../../assets/images/SalonsPageImages/chevron-right.png"),
      name: "Geetanjali Salon",
      rating: 3,
      ratingsCount: 161,
      location: "Koramangala, Bengaluru",
      distance: 70,
      unit:"m",
      services: [
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "299" },
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "199" },
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "99" },
      ],
      recommended:false,
      niche:"Facial and Makeup",
      venueType:"everyone",
    },
    {
      cardImage: require("../../assets/images/SalonsPageImages/cardImage.png"),
      star: require("../../assets/images/SalonsPageImages/star.png"),
      ellipse: require("../../assets/images/SalonsPageImages/Ellipse.png"),
      mapPin: require("../../assets/images/SalonsPageImages/map-pin.png"),
      chevronRight: require("../../assets/images/SalonsPageImages/chevron-right.png"),
      name: "Geetanjali Salon",
      rating: 3,
      ratingsCount: 161,
      location: "Koramangala, Bengaluru",
      distance: 70,
      unit:"m",
      services: [
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "299" },
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "199" },
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "99" },
      ],
      recommended:false,
      niche:"Facial and Makeup",
      venueType:"everyone",
    },
    {
      cardImage: require("../../assets/images/SalonsPageImages/cardImage.png"),
      star: require("../../assets/images/SalonsPageImages/star.png"),
      ellipse: require("../../assets/images/SalonsPageImages/Ellipse.png"),
      mapPin: require("../../assets/images/SalonsPageImages/map-pin.png"),
      chevronRight: require("../../assets/images/SalonsPageImages/chevron-right.png"),
      name: "Geetanjali Salon",
      rating: 3,
      ratingsCount: 161,
      location: "Koramangala, Bengaluru",
      distance: 70,
      unit:"m",
      services: [
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "299" },
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "199" },
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "99" },
      ],
      recommended:false,
      niche:"Hair straightening",
      venueType:"everyone",
    },
    {
      cardImage: require("../../assets/images/SalonsPageImages/cardImage.png"),
      star: require("../../assets/images/SalonsPageImages/star.png"),
      ellipse: require("../../assets/images/SalonsPageImages/Ellipse.png"),
      mapPin: require("../../assets/images/SalonsPageImages/map-pin.png"),
      chevronRight: require("../../assets/images/SalonsPageImages/chevron-right.png"),
      name: "Geetanjali Salon",
      rating: 3,
      ratingsCount: 161,
      location: "Koramangala, Bengaluru",
      distance: 70,
      unit:"m",
      services: [
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "299" },
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "199" },
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "99" },
      ],
      recommended:false,
      niche:"Nail salon",
      venueType:"everyone",
    },
    {
      cardImage: require("../../assets/images/SalonsPageImages/cardImage.png"),
      star: require("../../assets/images/SalonsPageImages/star.png"),
      ellipse: require("../../assets/images/SalonsPageImages/Ellipse.png"),
      mapPin: require("../../assets/images/SalonsPageImages/map-pin.png"),
      chevronRight: require("../../assets/images/SalonsPageImages/chevron-right.png"),
      name: "Geetanjali Salon",
      rating: 3,
      ratingsCount: 161,
      location: "Koramangala, Bengaluru",
      distance: 70,
      unit:"m",
      services: [
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "299" },
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "199" },
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "99" },
      ],
      recommended:false,
      niche:"Hair color",
      venueType:"everyone",
    },
    {
      cardImage: require("../../assets/images/SalonsPageImages/cardImage.png"),
      star: require("../../assets/images/SalonsPageImages/star.png"),
      ellipse: require("../../assets/images/SalonsPageImages/Ellipse.png"),
      mapPin: require("../../assets/images/SalonsPageImages/map-pin.png"),
      chevronRight: require("../../assets/images/SalonsPageImages/chevron-right.png"),
      name: "Geetanjali Salon",
      rating: 3,
      ratingsCount: 161,
      location: "Koramangala, Bengaluru",
      distance: 70,
      unit:"m",
      services: [
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "299" },
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "199" },
        { name: "Hair cut ladies", timing: "1 hr 15 mins", price: "99" },
      ],
      recommended:false,
      niche:"Hair color",
      venueType:"female",
    },
  ];